.main-content {
    .right-content-wrapper {
        img{
            margin-bottom: 1rem;
        }
        .max-width-480{
            max-width: 480px;
        }
        .max-width-700{
            max-width: 700px;
        }
    }
}